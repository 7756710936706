import React, { FC } from 'react';
import { ArrowLink } from '../../../../ui';
import styled from '@emotion/styled';
import {
	IExternalLinkEntry,
	IInternalLinkEntry,
} from '../../../shared/api/contentful/types';
import { useUrlForLink } from '../../../app/pages/ui/utils/useUrlForLink';

interface IReadMoreLinkProps {
	link: IInternalLinkEntry | IExternalLinkEntry;
}

export const StyledReadMoreLink = styled(ArrowLink)<{}>`
	display: inline-flex;
`;

export const ReadMoreLink: FC<IReadMoreLinkProps> = ({ link }) => {
	const url = useUrlForLink(link);

	return url ? (
		<StyledReadMoreLink href={url}>{link.fields.title}</StyledReadMoreLink>
	) : null;
};
