import React from 'react';
import log from 'loglevel';
import {
	ACCORDION_SECTION_TYPE_ID,
	AIRLINE_SECTION_TYPE_ID,
	ALERT_SECTION_TYPE_ID,
	BANNER_SECTION_ID,
	CALL_TO_ACTION_SECTION_TYPE_ID,
	CARD_SECTION_TYPE_ID,
	CONTACT_SECTION_TYPE_ID,
	HIGHLIGHT_SECTION_TYPE_ID,
	IFRAME_SECTION_TYPE_ID,
	INSTRUCTION_SECTION_TYPE_ID,
	NEWS_SECTION_TYPE_ID,
	PARKING_WIDGET_TYPE_ID,
	QUICK_LINKS_SECTION_TYPE_ID,
	ROYALTY_TIER_SECTION_TYPE_ID,
	TEXT_SECTION_TYPE_ID,
} from '../../shared/api/contentful/constants';
import { ISection, MERGED_QUICK_LINK_SECTIONS_TYPE_ID } from '../domain/models';
import {
	AccordionSection,
	AirlineSection,
	AlertSection,
	CallToActionSection,
	CardSection,
	ContactSection,
	HighlightSection,
	IframeSection,
	MergedQuickLinksSection,
	QuickLinksSection,
	TextSection,
} from './components';
import { BannerSection } from './components/BannerSection';
import { InstructionSection } from './components/InstructionSection';
import { RoyaltyProgramSection } from './components/RoyaltyProgramSection';
import { NewsSection } from '../../news/ui/components/NewsSection';

export function renderSectionComponent(section: ISection) {
	switch (section.contentType) {
		case ACCORDION_SECTION_TYPE_ID:
			return <AccordionSection section={section} />;

		case ALERT_SECTION_TYPE_ID:
			return <AlertSection section={section} />;

		case AIRLINE_SECTION_TYPE_ID:
			return <AirlineSection section={section} />;

		case CALL_TO_ACTION_SECTION_TYPE_ID:
			return <CallToActionSection section={section} />;

		case CARD_SECTION_TYPE_ID:
			return <CardSection section={section} />;

		case NEWS_SECTION_TYPE_ID:
			return <NewsSection section={section} />;

		case CONTACT_SECTION_TYPE_ID:
			return <ContactSection section={section} />;

		case HIGHLIGHT_SECTION_TYPE_ID:
			return <HighlightSection section={section} />;

		case IFRAME_SECTION_TYPE_ID:
			return <IframeSection section={section} />;

		case MERGED_QUICK_LINK_SECTIONS_TYPE_ID:
			return <MergedQuickLinksSection section={section} />;

		case PARKING_WIDGET_TYPE_ID:
			// NB: The Parking Widget will only be allowed as custom implementation on the Parking pages to ensure layout consistency (placed at the top of the page)
			return null;

		case QUICK_LINKS_SECTION_TYPE_ID:
			return <QuickLinksSection section={section} />;

		case TEXT_SECTION_TYPE_ID:
			return <TextSection section={section} />;

		case BANNER_SECTION_ID:
			return <BannerSection section={section} />;

		case INSTRUCTION_SECTION_TYPE_ID:
			return <InstructionSection section={section} />;

		case ROYALTY_TIER_SECTION_TYPE_ID:
			return <RoyaltyProgramSection section={section} />;

		default:
			const invalidSection: never = section;

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			log.error(`Unknown content type: ${(invalidSection as any).contentType}`);
	}

	return null;
}
