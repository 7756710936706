import React, { FC } from 'react';
import { IQuickLinkItem, QuickLinks } from '../../../../ui';
import { Section } from './Section';
import { IQuickLinksSectionEntry } from '../../../shared/api/contentful/types';
import { getLinkItemsForEntries } from '../../domain/use-cases';
import { useSiteMap } from '../../../app/pages/ui/utils/withSiteMap';

export interface IQuickLinksSectionProps {
	section: IQuickLinksSectionEntry;
}

export const QuickLinksSection: FC<IQuickLinksSectionProps> = ({ section }) => {
	const siteMap = useSiteMap();
	const items = section.fields.links
		? getLinkItemsForEntries<IQuickLinkItem>(section.fields.links, siteMap)
		: [];

	return (
		<Section>
			{section.fields.title && <h2>{section.fields.title}</h2>}
			<QuickLinks
				columns={items.length >= 6 ? [2, { tablet: 1 }] : 1}
				items={items}
			/>
		</Section>
	);
};
