import { css } from '@emotion/core';
import { IBreakpointName } from '../../../ui';
import Responsive from '../../../ui/utils/Responsive';

export function getMaxWidthBasedOnColumns(
	columns: number | [number, Partial<{ [key in IBreakpointName]: number }>],
	minWidthMobile?: boolean,
) {
	const maxWidth = Array.isArray(columns)
		? `calc(100% / ${columns[0]})`
		: `calc(100% / ${columns})`;

	return css`
		max-width: ${maxWidth};

		@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
			max-width: none;
			${minWidthMobile ? `min-width: 212px;` : ''}
		}
	`;
}
