import React from 'react';
import {
	ParkingAvailabilityItem,
	ParkingAvailabilityItemShimmer,
} from './parkingAvailabilityItem/ParkingAvailabilityItem';
import { IParkingLocation } from '../types/IParkingLocation';

interface IParkingAvailabilityLocationsProps {
	isLoading: boolean;
	locations: IParkingLocation[];
}

export const ParkingAvailabilityLocations = ({
	locations,
	isLoading,
}: IParkingAvailabilityLocationsProps) => {
	if (isLoading)
		return (
			<>
				<ParkingAvailabilityItemShimmer />
				<ParkingAvailabilityItemShimmer />
			</>
		);
	if (locations.length === 0) return null;
	return (
		<>
			{locations.map((parkingLocation) => (
				<ParkingAvailabilityItem
					key={parkingLocation.name}
					parkingLocation={parkingLocation}
				/>
			))}
		</>
	);
};
