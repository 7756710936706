import React from 'react';
import { TagButtonWrap } from '../../../../ui';
import { ParkingAvailabilityCategory } from '../types/ParkingAvailabilityCategory';
import { ParkingAvailabilityFilterButton } from './ParkingAvailabilityFilterButton';

interface IParkingAvailabilityProps {
	onToggle: (selected: ParkingAvailabilityCategory) => void;
	activeCategory?: Set<ParkingAvailabilityCategory>;
}

export const ParkingAvailabilityFilters = ({
	onToggle,
	activeCategory,
}: IParkingAvailabilityProps) => (
	<TagButtonWrap>
		{Object.values(ParkingAvailabilityCategory)
			.filter((c) => c !== ParkingAvailabilityCategory.NONE)
			.map((category) => (
				<ParkingAvailabilityFilterButton
					key={category}
					active={activeCategory?.has(category) ?? false}
					onChange={() => onToggle(category)}
					name={category}
				/>
			))}
	</TagButtonWrap>
);
