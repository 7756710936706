import React from 'react';
import { ICallToActionLink } from '../../../../ui';
import { Section } from './Section';
import { ICallToActionSectionEntry } from '../../../shared/api/contentful/types';
import { CallToAction } from '../../../shared/components/call-to-action';
import { getLinkItemsForEntries } from '../../domain/use-cases';
import { withSiteMap } from '../../../app/pages/ui/utils/withSiteMap';

export interface ICallToActionsSectionProps {
	section: ICallToActionSectionEntry;
}

export const CallToActionSection = withSiteMap<ICallToActionsSectionProps>(
	({ section, siteMap }) => {
		const links = getLinkItemsForEntries<ICallToActionLink>(
			section.fields.links,
			siteMap,
		);

		return (
			<Section>
				<CallToAction
					callToActionTheme={section.fields.theme}
					title={section.fields.title}
					links={links}
					description={section.fields.description}
				/>
			</Section>
		);
	},
);
