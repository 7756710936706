import styled from '@emotion/styled';
import React from 'react';
import { TextButton } from '../../../../ui';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import illustration from '../../../../../assets/flights-not-found.svg?reference';
import Responsive from '../../../../ui/utils/Responsive';

export const ParkingAvailabilityError = ({
	onReload,
}: {
	onReload: () => void;
}) => {
	const { t } = useTranslation();

	return (
		<StyledErrorMessage>
			<img
				src={illustration}
				alt={t('live_parking_availability_error_img_alt')}
				role="presentation"
			/>
			<h3>{t('live_parking_availability_error_header')}</h3>
			<p>{t('live_parking_availability_error_body')}</p>
			<TextButton variant="highlight" onClick={onReload}>
				{t('live_parking_availability_error_button')}
			</TextButton>
		</StyledErrorMessage>
	);
};
const StyledErrorMessage = styled.div`
	padding: 80px 0;
	text-align: center;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: 0;
	}

	img {
		display: block;
		width: 80%;
		max-width: 220px;
		margin: 0 auto 40px;
	}

	h3,
	p {
		max-width: 448px;
		margin-left: auto;
		margin-right: auto;
	}

	h3 {
		font-size: 20px;
	}
`;
