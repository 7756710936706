import { useRemoteConfigValue } from '../../../../shared/remote-config/ui/useRemoteConfigValue';

export const DEFAULT_START_TIME_MINIMUM_OFFSET_IN_SECONDS = 6 * 60 * 60; // 6 hours

export function useStartTimeMinimumOffsetInSeconds() {
	const { value } = useRemoteConfigValue(
		'parking_booking_entry_time_minimum_offset',
	);

	return value ?? DEFAULT_START_TIME_MINIMUM_OFFSET_IN_SECONDS;
}
