import React from 'react';
import { Accordion, AccordionItem, ITheme, Markdown } from '../../../../ui';
import styled from '@emotion/styled';
import { IAccordionSectionEntry } from '../../../shared/api/contentful/types';
import { Section } from './Section';
import { ReadMoreLink, StyledReadMoreLink } from './ReadMoreLink';
import { getListedEntryKey } from '../../../shared/utils/getListedEntryKey';

export interface IAccordionSectionsProps {
	section: IAccordionSectionEntry;
}

const StyledAccordionSection = styled(Section)<{}, ITheme>`
	${StyledReadMoreLink} {
		margin-top: 24px;
	}
`;

export const AccordionSection = ({ section }: IAccordionSectionsProps) => (
	<StyledAccordionSection>
		{section.fields.title && section.fields.title.trim() !== '' && (
			<h2>{section.fields.title}</h2>
		)}
		<Accordion>
			{section.fields.items &&
				section.fields.items.map((item, index) => (
					<AccordionItem
						key={getListedEntryKey(item, index)}
						title={item.fields.title}
					>
						<Markdown source={item.fields.body} />
					</AccordionItem>
				))}
		</Accordion>
		{section.fields.readMoreLink && (
			<ReadMoreLink link={section.fields.readMoreLink} />
		)}
	</StyledAccordionSection>
);
