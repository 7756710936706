import React from 'react';
import { ContactItem, ContactItemContainer } from '../../../shared/components';
import {
	IAirlineEntry,
	IAirlineSectionEntry,
} from '../../../shared/api/contentful/types';
import { Section } from './Section';
import { getContactImageSource } from './ContactSection';
import NewsCardNoImage from '../../../../../assets/icons/no-image.svg?reference';
import {
	ITranslationFunction,
	withTranslate,
} from '../../../shared/i18n/translate';
import { getListedEntryKey } from '../../../shared/utils/getListedEntryKey';

export interface IAirlineSectionProps {
	section: IAirlineSectionEntry;
}

export const AirlineSection = withTranslate<IAirlineSectionProps>(
	({ section, t }) => {
		const linkTitle = t('contact_item_visit_website');

		return (
			<Section>
				{section.fields.title && <h2>{section.fields.title}</h2>}
				<ContactItemContainer>
					{section.fields.items?.map((item, index) =>
						renderAirline(item, index, linkTitle, t),
					)}
				</ContactItemContainer>
			</Section>
		);
	},
);

function renderAirline(
	item: IAirlineEntry,
	index: number,
	linkTitle: string,
	t: ITranslationFunction,
) {
	const { name, logo, logoBackgroundColor, phoneNumber, website } = item.fields;

	return (
		<ContactItem
			key={getListedEntryKey(item, index)}
			title={name}
			screenReaderLinkTitle={t('generic_external_link_label')}
			imageAlt=""
			imageSource={
				logo
					? getContactImageSource(logo, logoBackgroundColor)
					: NewsCardNoImage
			}
			phoneNumber={phoneNumber}
			linkTitle={linkTitle}
			linkHref={website}
			phoneImageLabel={t('contact_phone_image_label')}
		/>
	);
}
