import React from 'react';

export const LoyaltyTierItemStars = ({
	id,
	colors: [primary, secondary],
}: {
	id: string;
	colors: string[];
}) => (
	<div>
		<svg
			width="28"
			height="22"
			viewBox="0 0 28 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M22.3133 20.3864C22.4278 20.3269 22.5641 20.3268 22.6787 20.3862L25.0818 21.6318C25.3732 21.7828 25.7114 21.5349 25.6551 21.2117L25.1988 18.5893C25.1762 18.4593 25.2197 18.3265 25.3149 18.2351L27.2456 16.3824C27.4842 16.1535 27.3544 15.7504 27.0271 15.7036L24.3534 15.3216C24.2247 15.3032 24.1131 15.2229 24.0549 15.1066L22.8543 12.7084C22.7081 12.4163 22.2912 12.4163 22.145 12.7084L20.9445 15.1066C20.8862 15.2229 20.7747 15.3032 20.6459 15.3216L17.9722 15.7036C17.645 15.7504 17.5152 16.1535 17.7537 16.3824L19.6844 18.2351C19.7796 18.3265 19.8232 18.4593 19.8006 18.5893L19.3444 21.2107C19.2881 21.5341 19.6267 21.782 19.918 21.6307L22.3133 20.3864Z"
				fill={`url(#${id})`}
			/>
			<path
				opacity="0.5"
				d="M11.2555 5.03181C11.3242 4.99612 11.4059 4.99608 11.4747 5.03171L12.9166 5.77907C13.0914 5.86966 13.2944 5.72094 13.2606 5.52699L12.9868 3.95358C12.9732 3.87556 12.9994 3.7959 13.0565 3.74107L14.2149 2.62947C14.3581 2.49213 14.2802 2.25024 14.0838 2.22218L12.4796 1.99298C12.4023 1.98194 12.3354 1.93372 12.3004 1.86393L11.5801 0.425054C11.4923 0.249799 11.2422 0.249799 11.1545 0.425054L10.4341 1.86393C10.3992 1.93372 10.3323 1.98194 10.255 1.99298L8.65075 2.22218C8.45438 2.25024 8.37651 2.49213 8.51964 2.62947L9.67808 3.74107C9.73522 3.7959 9.76134 3.87556 9.74776 3.95358L9.47407 5.52644C9.4403 5.72049 9.64343 5.86921 9.81822 5.77841L11.2555 5.03181Z"
				fill="white"
			/>
			<path
				opacity="0.5"
				d="M3.25546 21.0318C3.32417 20.9961 3.40594 20.9961 3.47467 21.0317L4.91664 21.7791C5.09143 21.8697 5.29435 21.7209 5.2606 21.527L4.98681 19.9536C4.97323 19.8756 4.99935 19.7959 5.05649 19.7411L6.21493 18.6295C6.35806 18.4921 6.28019 18.2502 6.08382 18.2222L4.47958 17.993C4.40231 17.9819 4.33538 17.9337 4.30044 17.8639L3.58008 16.4251C3.49234 16.2498 3.24223 16.2498 3.15449 16.4251L2.43413 17.8639C2.3992 17.9337 2.33226 17.9819 2.25499 17.993L0.650752 18.2222C0.454377 18.2502 0.376511 18.4921 0.519642 18.6295L1.67808 19.7411C1.73522 19.7959 1.76134 19.8756 1.74776 19.9536L1.47407 21.5264C1.4403 21.7205 1.64343 21.8692 1.81822 21.7784L3.25546 21.0318Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id={id}
					x1="17.1663"
					y1="12"
					x2="27.1456"
					y2="22.6445"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor={primary} />
					<stop offset="0.767045" stopColor={secondary} />
				</linearGradient>
			</defs>
		</svg>
	</div>
);
