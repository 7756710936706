import React from 'react';
import { Flex, ITheme, Row } from '../../../../ui';
import styled from '@emotion/styled';
import { IImageAsset } from '@move-frontend/contentful';
import { SolidContentWrap } from '../../../shared/components';
import loyaltyTierCurve from '../../../../../assets/loyalty-tier-curve.svg?reference';
import CheckRoundedIconSvg from '../../../../../assets/check-rounded.svg';
import { getGradientBackground } from '../../../shared/styles';
import { IBenefitItemEntry } from '../../../shared/api/contentful/types/IBenefitItem';
import { Color } from '../../../shared/styles/Color';
import { ILoyaltyTier } from '../../../shared/api/contentful/types/ILoyaltyTier';
import { StyledUserTierBadge } from '../../../account/ui/current-tier-points/UserTierBadge';
import { LoyaltyTierItemStars } from '../LoyaltyTierItemStars';
import Responsive from '../../../../ui/utils/Responsive';

export interface ILoyaltyTierItemProps {
	item: ILoyaltyTier;
}

export const LoyaltyTierItem = ({ item }: ILoyaltyTierItemProps) => (
	<StyledLoyaltyTierItem>
		<StyledHeader>
			<StyledHeaderContent
				primary={`#${item.primaryColor}`}
				secondary={`#${item.secondaryColor}`}
			>
				<LoyaltyTierItemStars
					id={item.id}
					colors={[`#${item.primaryColor}`, `#${item.secondaryColor}`]}
				/>
				<StyledHeaderTier direction="column" justifyContent="center">
					<StyledUserTierBadge src={item.image.fields.file.url} />
					{item.title}
				</StyledHeaderTier>
				<LoyaltyTierItemStars
					id={item.id}
					colors={[`#${item.primaryColor}`, `#${item.secondaryColor}`]}
				/>
			</StyledHeaderContent>
		</StyledHeader>

		<StyledContentWrap>
			<StyledSolidBenefitsWrap>
				<StyledTierDescription>{item.description}</StyledTierDescription>
				<Row />
				{item.items.map((benefit: IBenefitItemEntry) => (
					<StyledBenefitItem key={benefit.id}>
						<StyledBenefitItemPoint
							primary={item.primaryColor}
							secondary={item.secondaryColor}
						>
							<CheckRoundedIconSvg />
						</StyledBenefitItemPoint>
						{benefit.fields.title && <p>{benefit.fields.title}</p>}
					</StyledBenefitItem>
				))}
			</StyledSolidBenefitsWrap>
		</StyledContentWrap>
	</StyledLoyaltyTierItem>
);

const StyledLoyaltyTierItem = styled('div')<{}, ITheme>`
	width: 282px;
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: center;

	border-radius: 12px;
	background: rgba(247, 247, 247, 1);

	${getGradientBackground('purple-blue')};

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		min-width: 279px;

		flex-direction: column;
	}
`;

const StyledHeader = styled.div<{}, ITheme>`
	width: 100%;
	height: 180px;

	display: flex;

	justify-content: center;
	align-items: center;

	font-size: 24px;
	font-weight: 700;
	line-height: 28.8px;
	text-align: left;
	color: ${({ theme }) => theme.color.text.inverted};
`;

const StyledHeaderTier = styled(Flex)`
	height: 120px;
	gap: 32px;
`;

const StyledHeaderContent = styled.div<
	{ primary: string; secondary: string },
	ITheme
>`
	width: 100%;
	height: 130px;

	display: flex;
	gap: 10px;
	justify-content: center;
	align-items: center;

	div:nth-of-type(1) {
		align-self: center;
	}
	div:nth-of-type(2) {
		height: 100%;
	}
	div:nth-of-type(3) {
		align-self: start;
		transform: scaleX(-1);
	}
`;

const StyledTierDescription = styled.p`
	margin: 0;
	padding: 16px;
	font-size: 18px;
	font-weight: 700;
	line-height: 21px;
`;

const StyledSolidBenefitsWrap = styled(SolidContentWrap)`
	width: 247px;
	height: fit-content;

	z-index: 1;
	display: flex;
	flex-direction: column;
	align-items: center;

	border-radius: 8px;
	margin: 0;
	padding: 0;

	text-align: center;
`;

const StyledContentWrap = styled('div')`
	width: 100%;
	height: calc(100% - 150px);

	position: relative;
	display: flex;
	justify-content: center;

	&:after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -2px;
		top: -10px;
		border-radius: 12px;
		background: url(${loyaltyTierCurve});
		background-repeat: no-repeat;
		background-size: 100%;
		background-position: center bottom;
	}

	${StyledSolidBenefitsWrap} {
		margin-top: 0;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		height: calc(100% - 180px);
	}
`;

const StyledBenefitItem = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	gap: 8px;

	padding: 16px;
	border-top: 1px solid ${Color.Neutral90};

	font-size: 16px;
	font-weight: 400;
	line-height: 19.2px;
	text-align: left;
`;

const StyledBenefitItemPoint = styled.div<
	{ primary: IImageAsset; secondary: IImageAsset },
	ITheme
>`
	width: 18px;
	height: 18px;

	padding: 4px;
	border-radius: 50%;
	background: ${({ primary, secondary }) =>
		`linear-gradient(225deg, #${primary} 0%, #${secondary} 76.7%)`};

	svg {
		path {
			stroke: white;
		}
	}

	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
`;
