import { INTERNAL_LINK_TYPE_ID } from '@move-frontend/contentful';
import { PassengersPageId } from '../../../app/pages/domain/constants/PageId';
import { CARD_SECTION_TYPE_ID } from '../../../shared/api/contentful/constants';
import {
	ICardItemEntry,
	ICardSectionEntry,
} from '../../../shared/api/contentful/types';
import { ISection } from '../models';

export function excludeEntryPoint(
	sections: ISection[] = [],
	isEnabled?: boolean,
) {
	let filteredCardSections: ICardSectionEntry[] = [];
	if (isEnabled === false) {
		const cardSections: ICardSectionEntry[] = sections.filter(isCardSection);

		filteredCardSections = cardSections.map((section: ICardSectionEntry) => {
			const items = section.fields.items?.filter(
				(item) => !isParkingAvailability(item),
			);

			return {
				...section,
				fields: {
					...section.fields,
					items,
				},
			};
		});
	}

	const filteredSections = sections.map((section) => {
		if (isCardSection(section) && filteredCardSections.length > 0) {
			const firstCardSection = filteredCardSections[0];
			filteredCardSections.splice(0);

			return firstCardSection;
		}
		return section;
	});

	return filteredSections;
}

function isCardSection(section: ISection): section is ICardSectionEntry {
	return section.contentType === CARD_SECTION_TYPE_ID;
}

function isParkingAvailability(item: ICardItemEntry) {
	if (item.fields.link?.contentType === INTERNAL_LINK_TYPE_ID) {
		const link = item.fields.link.fields;
		return link.page?.fields.id === PassengersPageId.ParkingAvailability;
	}
	return false;
}
