import React from 'react';
import { ParkingSearch } from '../../../parking/ui/search/ParkingSearch';
import { useStartTimeMinimumOffsetInSeconds } from '../../../parking/ui/search/helpers/useStartTimeMinimumOffsetInSeconds';
import { IParkingWidgetEntry } from '../../../shared/api/contentful/types';

export interface IParkingSectionProps {
	section: IParkingWidgetEntry;
}

export const ParkingSection = ({ section }: IParkingSectionProps) => {
	const startTimeMinimumOffsetInSeconds = useStartTimeMinimumOffsetInSeconds();

	/**
	 * We need to be able to handle the following edge case:
	 * The user might exit the Full Amend flow at any time, navigating to any page outside the flow.
	 * When that happens we need to make sure that whenever they return on a content page with a Parking Section,
	 * the Full Amend flow is cancelled, and when applying search, a New Booking flow is started instead.
	 */
	const shouldResetOriginalBookingState = true;

	return (
		<ParkingSearch
			title={section.fields.title}
			startTimeMinimumOffsetInSeconds={startTimeMinimumOffsetInSeconds}
			shouldResetOriginalBookingState={shouldResetOriginalBookingState}
		/>
	);
};
