import React, { useEffect, useState } from 'react';
import { H3, Link, Shimmer, ShimmerPlaceholderText } from '../../../../../ui';
import styled from '@emotion/styled';
import { IParkingLocation } from '../../types/IParkingLocation';
import { useUrlForPageId } from '../../../../app/pages/ui/utils/useUrlForPageId';
import { PASSENGERS_PARKING_LANDING_PAGE_ID } from '../../../../shared/api/contentful/constants';
import { useTranslation } from '../../../../shared/i18n/translate/useTranslation';
import { ParkingAvailabilityStatus } from '../../types/ParkingAvailabilityStatus';
import { ParkingAvailabilityTier } from '../../types/ParkingAvailabilityTier';

interface IParkingAvailabilityItemProps {
	parkingLocation: IParkingLocation;
}

export const ParkingAvailabilityItem = ({
	parkingLocation: { name, status, total, available, tier, directionsUrl },
}: IParkingAvailabilityItemProps) => {
	const { t } = useTranslation();
	const parkingUrl = useUrlForPageId(PASSENGERS_PARKING_LANDING_PAGE_ID);
	const [percentLeft, setPercentLeft] = useState(0);
	useEffect(() => {
		if (available && total) {
			setPercentLeft((available / total) * 100);
		}
	}, [available, total]);
	return (
		<StyledItem>
			<StyledTitleContainer>
				<StyledTitle id={name}>{name}</StyledTitle>
				<StyledOccupancy
					available={available}
					status={status}
					percentLeft={percentLeft}
				>
					<StyledOccupancyStatus
						available={available}
						status={status}
						tier={tier}
					>
						{t(
							available === 0
								? 'live_parking_availability_state_closed'
								: `live_parking_availability_state_${
										status === ParkingAvailabilityStatus.OPEN ||
										status === ParkingAvailabilityStatus.WARNING
											? 'open'
											: 'closed'
								  }`,
						)}
					</StyledOccupancyStatus>
					{available &&
					available !== 0 &&
					tier === ParkingAvailabilityTier.NEVER &&
					(status === ParkingAvailabilityStatus.OPEN ||
						status === ParkingAvailabilityStatus.WARNING) ? (
						<StyledOccupancyAmount>{available}</StyledOccupancyAmount>
					) : null}
				</StyledOccupancy>
			</StyledTitleContainer>
			<StyledSub>
				<Link href={directionsUrl} target="_blank" aria-describedby={name}>
					{t('live_parking_availability_subtitle')}
				</Link>
				{tier !== ParkingAvailabilityTier.NEVER && (
					<Link href={parkingUrl} aria-describedby={name}>
						{t(
							`live_parking_availability_tier_book_${
								tier === ParkingAvailabilityTier.NOW ? 'now' : 'later'
							}`,
						)}
					</Link>
				)}
			</StyledSub>
		</StyledItem>
	);
};

export const ParkingAvailabilityItemShimmer = () => (
	<Shimmer aria-label="shimmer">
		<StyledItem>
			<StyledShimmerTitleContainer>
				<ShimmerPlaceholderText />
				<ShimmerPlaceholderText />
			</StyledShimmerTitleContainer>
			<StyledShimmerSub>
				<ShimmerPlaceholderText />
				<ShimmerPlaceholderText />
			</StyledShimmerSub>
		</StyledItem>
	</Shimmer>
);

const StyledItem = styled.div`
	display: grid;
	border: 1px solid #0000001a;
	padding: 10px 10px 0 10px;
`;

const StyledTitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #0000001a;
	padding-bottom: 8px;
	gap: 0.5rem;
`;

const StyledTitle = styled(H3)`
	margin: 0;
`;

const StyledOccupancy = styled.div<{
	percentLeft?: number;
	available?: number;
	status?: ParkingAvailabilityStatus;
}>`
	padding: 5px 10px;

	${({ available, status, percentLeft }) =>
		status === ParkingAvailabilityStatus.CLOSED || available === 0
			? `background-color: #FFE2E0;
		color: #CC1100;`
			: status === ParkingAvailabilityStatus.WARNING ||
			  (percentLeft &&
					percentLeft < 15 &&
					status === ParkingAvailabilityStatus.OPEN)
			? `background-color: #FBF0D0;
			color: #7E610C;`
			: `background-color: #D9F5DD;
		color: #007500;`}
`;

const StyledOccupancyStatus = styled.span<{
	available?: number;
	status?: ParkingAvailabilityStatus;
	tier?: ParkingAvailabilityTier;
}>`
	${({ available, status, tier }) =>
		available !== null &&
		available !== 0 &&
		tier === ParkingAvailabilityTier.NEVER &&
		(status === ParkingAvailabilityStatus.OPEN ||
			status === ParkingAvailabilityStatus.WARNING)
			? `
			&:after {
				content: ': ';
			}
		`
			: `font-weight: 700;`}
`;

const StyledOccupancyAmount = styled.span`
	font-weight: 700;
`;

const StyledSub = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	padding: 0.2rem 0;
`;

const StyledShimmerTitleContainer = styled(StyledTitleContainer)`
	gap: 24px;
`;

const StyledShimmerSub = styled(StyledSub)`
	gap: 24px;
	padding: 0.5rem 0;
`;
