import { TagButton } from '../../../../ui';
import React from 'react';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { ParkingAvailabilityCategory } from '../types/ParkingAvailabilityCategory';

interface IButtonProps {
	active: boolean;
	onChange: (active: boolean) => void;
	name: ParkingAvailabilityCategory;
}

export const ParkingAvailabilityFilterButton = ({
	active,
	onChange,
	name,
}: IButtonProps) => {
	const { t } = useTranslation();
	return (
		<TagButton
			active={active}
			onClick={() => onChange(!active)}
			aria-current={active}
		>
			{t(
				`live_parking_availability_filter_${
					name === ParkingAvailabilityCategory.DECK
						? 'deck'
						: name === ParkingAvailabilityCategory.VALET
						? 'valet'
						: 'all'
				}`,
			)}
		</TagButton>
	);
};
