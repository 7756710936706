import React from 'react';
import { ITheme, Markdown } from '../../../../ui';
import styled from '@emotion/styled';
import { IInstructionItemEntry } from '../../../shared/api/contentful/types/IInstructionItem';
import Responsive from '../../../../ui/utils/Responsive';

export interface IInstructionItemProps {
	order: number;
	item: IInstructionItemEntry;
}

export const InstructionItem = ({ item, order }: IInstructionItemProps) => (
	<StyledInstructionItem>
		<InstructionItemOrder>{order}</InstructionItemOrder>
		<div>
			{item.fields.title && (
				<InstructionItemTitle>{item.fields.title}</InstructionItemTitle>
			)}

			{item.fields.body && <Markdown source={item.fields.body} />}
		</div>
	</StyledInstructionItem>
);
const StyledInstructionItem = styled('div')<{}, ITheme>`
	width: 384px;
	min-height: 166px;
	height: auto;
	padding: 32px 24px;

	display: flex;
	gap: 24px;
	align-items: center;

	border-radius: 12px;
	background: rgba(247, 247, 247, 1);

	p {
		line-height: 1.3;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		min-width: 270px;
		min-height: 184px;
		height: auto;

		flex-direction: column;
		padding: 24px 16px;

		gap: 16px;
		align-items: flex-start;
	}
`;

const InstructionItemTitle = styled.h3`
	font-size: 21px;
	line-height: 25.2px;
	text-align: left;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: 16px;
	}
`;

const InstructionItemOrder = styled.div`
	min-width: 32px;
	min-height: 32px;

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	background: rgba(0, 119, 200, 1);

	font-size: 17px;
	font-weight: 700;

	color: rgba(255, 255, 255, 1);
`;
