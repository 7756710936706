import styled from '@emotion/styled';
import Responsive from '../../../../ui/utils/Responsive';

export const StyledUserTierBadge = styled.img`
	width: 64px;
	height: 64px;
	display: block;
	filter: drop-shadow(0px 5px 13px rgba(0, 0, 0, 0.1));

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		width: 60px;
		height: 60px;
	}
`;
