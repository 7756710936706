import React from 'react';
import { ImageWrap, Markdown } from '../../../../ui';
import styled from '@emotion/styled';
import { ITextSectionEntry } from '../../../shared/api/contentful/types';
import { Section } from './Section';
import { ReadMoreLink } from './ReadMoreLink';
import themed from '../../../../ui/themer/themed';

const StyledMarkdownWrapper = styled.div`
	a {
		text-decoration: underline;
	}
`;

const CustomTextSection = styled(Section)`
	${ImageWrap} {
		img {
			max-width: ${themed(({ layout }) => layout.main.textMaxWidth)};
		}
	}
`;
export interface ITextSectionsProps {
	section: ITextSectionEntry;
}

export const TextSection = ({ section }: ITextSectionsProps) => (
	<CustomTextSection>
		<StyledMarkdownWrapper>
			<Markdown source={section.fields.body} />
		</StyledMarkdownWrapper>
		{section.fields.readMoreLink && (
			<ReadMoreLink link={section.fields.readMoreLink} />
		)}
	</CustomTextSection>
);
