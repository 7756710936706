import React from 'react';
import styled from '@emotion/styled';
import {
	getFixedGridColumnStyle,
	Gutter,
	IFixedGridPropsWithTheme,
	ITheme,
	QuickLinkItem,
	StyledQuickLinks,
} from '../../../../ui';
import { Section } from './Section';
import { renderSection } from '../renderSection';
import { IMergedQuickLinksSection } from '../../domain/models';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';
import Responsive from '../../../../ui/utils/Responsive';

interface IStyledMergedQuickLinkSectionProps
	extends Omit<IFixedGridPropsWithTheme, 'columns'> {}

const MERGED_QUICK_LINKS_COLUMNS = 2;

/**
 * Render two subsequent quick link sections with a two column layout on desktop.
 */
const StyledMergedQuickLinkSection = styled.div<
	IStyledMergedQuickLinkSectionProps,
	ITheme
>`
	display: flex;
	${Gutter.getWrapperStyles('horizontal')}
	${Gutter.getWrapperStyles('vertical')}

	${Section} {
		${(props: IStyledMergedQuickLinkSectionProps) =>
			getFixedGridColumnStyle(MERGED_QUICK_LINKS_COLUMNS, {
				columns: MERGED_QUICK_LINKS_COLUMNS,
				...props,
			})}
		${Gutter.getItemStyles('horizontal')}
		${Gutter.getItemStyles('vertical')}
	}

	${StyledQuickLinks} {
		display: block;

		${QuickLinkItem} {
			width: auto;
		}
	}

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		display: block;
		margin: 0;

		${Section} {
			width: auto;
			margin: 0;
		}
	}
`;

interface IMergedQuickLinksSectionProps {
	section: IMergedQuickLinksSection;
}

export const MergedQuickLinksSection = ({
	section,
}: IMergedQuickLinksSectionProps) => (
	<ContentWrap>
		<StyledMergedQuickLinkSection>
			{section.sections.map(renderSection)}
		</StyledMergedQuickLinkSection>
	</ContentWrap>
);
