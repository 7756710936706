import React from 'react';
import { SectionErrorBoundary } from './components/error';
import { renderSectionComponent } from './renderSectionComponent';
import { getListedEntryKey } from '../../shared/utils/getListedEntryKey';
import { ISection, MERGED_QUICK_LINK_SECTIONS_TYPE_ID } from '../domain/models';

export function renderSection(section: ISection, index: number) {
	const key =
		section.contentType === MERGED_QUICK_LINK_SECTIONS_TYPE_ID
			? section.id
			: getListedEntryKey(section, index);

	return (
		<SectionErrorBoundary
			key={key}
			entryId={section.id}
			contentType={section.contentType}
		>
			{renderSectionComponent(section)}
		</SectionErrorBoundary>
	);
}
