import styled from '@emotion/styled';

import { Link, ResponsivePicture } from '../../../../ui';
import React, { FC } from 'react';
import { useUrlForLink } from '../../../app/pages/ui/utils/useUrlForLink';

import { IBannerSectionEntry } from '../../../shared/api/contentful/types/IBannerSection';
import { getLinkPropsForUrl } from '../../../shared/components/link/utils/getLinkPropsForUrl';
import { getResponsiveImageUrl } from '../../../shared/image/getResponsiveImageUrl';

import { Section } from './Section';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';

export interface IBannerSectionProps {
	section: IBannerSectionEntry;
}

export const BannerSection: FC<IBannerSectionProps> = ({ section }) => {
	const { image, mobileImage } = section.fields;
	const url = useUrlForLink(section.fields.link);
	const { t } = useTranslation();

	if (!image || !mobileImage || !url) return null;

	const description =
		image.fields.description ||
		image.fields.title ||
		image.fields.file.fileName;

	return (
		<Section>
			<Link {...getLinkPropsForUrl(url)} hideExternalIndicator>
				<StyledPicture
					alt={t('banner_section_img_alt', { description })}
					source={[
						getResponsiveImageUrl(image.fields.file.url, {
							w: 2400,
						}),
						{
							mobileXL: getResponsiveImageUrl(mobileImage.fields.file.url, {
								w: 1200,
							}),
						},
					]}
				/>
			</Link>
		</Section>
	);
};

const StyledPicture = styled(ResponsivePicture)`
	img {
		max-width: 100%;
	}
`;
