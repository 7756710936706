import React from 'react';
import { FormattedTime, ReplayIcon, TextButton } from '../../../../ui';
import styled from '@emotion/styled';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import Responsive from '../../../../ui/utils/Responsive';

interface IParkingAvailabilityProps {
	onReload: () => void;
	lastUpdated?: Date;
}

export const ParkingAvailabilityRefresh = ({
	onReload,
	lastUpdated,
}: IParkingAvailabilityProps) => {
	const { t } = useTranslation();

	return (
		<StyledRefreshContainer>
			{lastUpdated && (
				<span>
					{t('live_parking_availability_refresh_label')}

					<FormattedTime value={lastUpdated} />
				</span>
			)}
			<StyledTextButton variant="highlight" onClick={onReload}>
				<span>
					{t('live_parking_availability_refresh_button')}{' '}
					<StyledWaitTimes>
						{t('live_parking_availability_refresh_button_subtitle')}
					</StyledWaitTimes>
				</span>
				<ReplayIcon variation="highlight" />
			</StyledTextButton>
		</StyledRefreshContainer>
	);
};

const StyledRefreshContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		flex-direction: column;
		align-items: flex-start;
		gap: 0.5rem;
	}
`;

const StyledTextButton = styled(TextButton)`
	display: flex;
	gap: 0.5rem;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: 0;
	}
`;

const StyledWaitTimes = styled.span`
	display: none;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: inline-block;
	}
`;
