import React from 'react';
import { Alert, Markdown } from '../../../../ui';

import { IAlertSectionEntry } from '../../../shared/api/contentful/types';
import { Section } from './Section';
import {
	ITranslationFunction,
	withTranslate,
} from '../../../shared/i18n/translate';
import {
	DATE_MED,
	moment,
	TIME_SIMPLE,
} from '../../../shared/i18n/date-time/moment';

export interface IAlertSectionsProps {
	section: IAlertSectionEntry;
}

export const AlertSection = withTranslate<IAlertSectionsProps>(
	({ section, t }) => (
		<Section>
			<Alert
				title={section.fields.title}
				subtitle={getFormattedSubtitle(section.updatedAt, t)}
			>
				<Markdown source={section.fields.body} />
			</Alert>
		</Section>
	),
);

function getFormattedSubtitle(isoDateString: string, t: ITranslationFunction) {
	const dateTime = moment(isoDateString).local();

	return t('web_alert_section_updated_at', {
		date: dateTime.format(DATE_MED),
		time: dateTime.format(TIME_SIMPLE),
	});
}
