'use client';

import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import {
	ArrowIcon,
	Css,
	H2,
	isRelativeUrl,
	Link,
	StyledIconButton,
} from '../../../../ui';
import { Color } from '../../../shared/styles/Color';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';
import Responsive from '../../../../ui/utils/Responsive';

interface IProps {
	title?: string;
	label?: string;
	children: ReactNode;
	viewAllHref?: string;
	viewAllLinkTo?: string;
	removeViewAllBtn?: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export default function NewsSectionWrapper({
	title,
	label,
	children,
	viewAllHref,
	viewAllLinkTo,
	removeViewAllBtn,
}: IProps) {
	const { t } = useTranslation();

	const viewAllButtonLabel = `${t('web_view_all_label')} ${
		viewAllLinkTo || ''
	}`;
	const ariaLabel = `${viewAllButtonLabel} ${
		!isRelativeUrl(viewAllHref || '') ? t('new_window_aria_label') : ''
	}`;

	const isMobileXL = Responsive.useMatchesBreakpoint('mobileXL');

	const renderViewAllButton = () => {
		if (removeViewAllBtn || !viewAllHref) return null;

		return (
			<StyledViewAllLink
				href={viewAllHref}
				target={!isRelativeUrl(viewAllHref || '') ? '_blank' : undefined}
				rel="noreferrer"
				aria-label={isMobileXL ? ariaLabel : undefined}
			>
				<span>{viewAllButtonLabel}</span>

				{isMobileXL ? (
					<ArrowIcon />
				) : (
					<StyledIconButton aria-label={ariaLabel}>
						<ArrowIcon />
					</StyledIconButton>
				)}
			</StyledViewAllLink>
		);
	};

	return (
		<SectionWrapper>
			<ContentWrap>
				<SectionTop>
					<div>
						{title && (
							<>
								<SectionLabel>{label}</SectionLabel>
								<SectionTitle>{title}</SectionTitle>
							</>
						)}
					</div>

					{!isMobileXL && renderViewAllButton()}
				</SectionTop>
				{children}

				{isMobileXL && renderViewAllButton()}
			</ContentWrap>
		</SectionWrapper>
	);
}

export const SectionWrapper = styled.div`
	flex-grow: 1;
	padding: ${Css.pxToRem(50)} 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding: ${Css.pxToRem(20)} 0;
	}
`;

const StyledViewAllLink = styled(Link)`
	display: flex;
	align-items: center;
	gap: 8px;
	color: ${Color.PrimaryDefault};

	${StyledIconButton} {
		padding: 0 6px;
		height: 40px;
		box-shadow: none;
		font-size: 21px;
	}

	svg {
		margin-left: 0 !important;
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		g {
			stroke: ${Color.PrimaryDefault};
		}
	}
`;

const SectionLabel = styled.small`
	text-transform: uppercase;
	font-size: ${Css.pxToRem(12)};
	letter-spacing: 1px;
	font-weight: 500;
	color: ${Color.PrimaryDark};
`;

const SectionTitle = styled(H2)`
	margin-top: -5px;
	margin-bottom: 0;
	flex: 1;
	color: ${Color.TextBlack};
	font-weight: 800;
	line-height: 48px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		font-size: ${Css.pxToRem(26)};
	}
`;

const SectionTop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	margin-bottom: 24px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		margin-bottom: 16px;
	}
`;
