import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import log from 'loglevel';
import { Section } from './Section';
import { IRoyaltyTierSectionEntry } from '../../../shared/api/contentful/types/IRoyaltyProgramSection';
import { ILoyaltyTierItemEntry } from '../../../shared/api/contentful/types/ILoyaltyTierItem';
import { LoyaltyTierItem } from './LoyaltyTierItem';
import { getLoyaltyTiers } from '../../../account/domain/use-cases/getLoyaltyTiers';
import { ILoyaltyTier } from '../../../shared/api/contentful/types/ILoyaltyTier';
import Responsive from '../../../../ui/utils/Responsive';

export interface IRoyaltyProgramSectionProps {
	section: IRoyaltyTierSectionEntry;
}

export const RoyaltyProgramSection = ({
	section,
}: IRoyaltyProgramSectionProps) => {
	const [allLoyaltyTiersInfo, setAllLoyaltyTiersInfo] =
		useState<ILoyaltyTier[]>();

	useEffect(() => {
		fetchLoyaltyTiers();
	}, []);

	const fetchLoyaltyTiers = useCallback(async () => {
		try {
			const result = await getLoyaltyTiers();
			setAllLoyaltyTiersInfo(result);
			return result;
		} catch (error) {
			log.error('Error fetching loyalty tiers:', error);
			return undefined;
		}
	}, []);

	const { title, items } = section.fields;

	return (
		<Section>
			{title && <h2>{title}</h2>}
			<StyledRoyaltyTierItemsWrapper>
				{items
					.map((item: ILoyaltyTierItemEntry) => {
						const currentItem = allLoyaltyTiersInfo?.find(
							(t: ILoyaltyTier) => t.id === item.fields.title?.toLowerCase(),
						);
						return renderRoyaltyTierItem(currentItem);
					})
					.filter((item) => item !== undefined)}
			</StyledRoyaltyTierItemsWrapper>
		</Section>
	);
};

function renderRoyaltyTierItem(item?: ILoyaltyTier) {
	if (!item) {
		return null;
	}
	return <LoyaltyTierItem key={item.id} item={item} />;
}

const StyledRoyaltyTierItemsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	margin-top: 16px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		overflow-x: scroll;
		overflow-y: hidden;
		flex-wrap: nowrap;
	}
`;
