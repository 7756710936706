import React, { FunctionComponent } from 'react';
import { IPageSectionEntry } from '../../shared/api/contentful/types';
import { ISection } from '../domain/models';
import { excludeEntryPoint } from '../domain/use-cases/excludeEntryPoint';
import { enrichSections as defaultEnrichSections } from '../domain/use-cases/enrichSections';
import { renderSection as defaultRenderSection } from './renderSection';
import { useRemoteConfigValue } from '../../shared/remote-config/ui/useRemoteConfigValue';

export interface ISectionRenderer<SectionType> {
	(section: SectionType, index: number): JSX.Element;
}

export interface ISectionsProps<
	SectionType = IPageSectionEntry,
	EnrichedSectionType = ISection,
> {
	sections: SectionType[];
	enrich?: (sections: SectionType[]) => EnrichedSectionType[];
	render?: ISectionRenderer<EnrichedSectionType>;
}

export interface ISectionsComponent<
	SectionType = IPageSectionEntry,
	EnrichedSectionType = ISection,
> extends FunctionComponent<ISectionsProps<SectionType, EnrichedSectionType>> {}

export const Sections: ISectionsComponent = ({
	sections,
	enrich: customEnrichSections,
	render: customRenderSection,
}: ISectionsProps) => {
	const enrichSections = customEnrichSections || defaultEnrichSections;
	const renderSection = customRenderSection || defaultRenderSection;

	const enrichedSections = enrichSections(sections);

	const { value: isParkingAvailabilityEnabled } = useRemoteConfigValue(
		'parking_availability_enabled',
	);

	const filteredSections = excludeEntryPoint(
		enrichedSections,
		isParkingAvailabilityEnabled,
	);

	return <>{filteredSections.map(renderSection)}</>;
};
