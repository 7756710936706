import React from 'react';
import styled from '@emotion/styled';
import { Section } from './Section';
import { getListedEntryKey } from '../../../shared/utils/getListedEntryKey';
import { IInstructionSectionEntry } from '../../../shared/api/contentful/types/IInstructionSection';
import { InstructionItem } from './InstructionItem';
import { IInstructionItemEntry } from '../../../shared/api/contentful/types/IInstructionItem';
import Responsive from '../../../../ui/utils/Responsive';

export interface IInstructionSectionProps {
	section: IInstructionSectionEntry;
}

export const InstructionSection = ({ section }: IInstructionSectionProps) => (
	<Section>
		{section.fields.title && <h2>{section.fields.title}</h2>}
		<StyledInstructionItemsWrapper>
			{section.fields.items.map((item: IInstructionItemEntry, index) =>
				renderInstructionItem(item, index),
			)}
		</StyledInstructionItemsWrapper>
	</Section>
);

function renderInstructionItem(item: IInstructionItemEntry, index: number) {
	return (
		<InstructionItem
			key={getListedEntryKey(item, index)}
			item={item}
			order={index + 1}
		/>
	);
}

const StyledInstructionItemsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 24px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		overflow-x: scroll;
		overflow-y: hidden;
		flex-wrap: nowrap;
	}
`;
