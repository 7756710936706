import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { H2 } from '../../../../ui';
import styled from '@emotion/styled';
import { LocationStore } from '../stores/LocationStore';
import { ParkingAvailabilityFilters } from './ParkingAvailabilityFilters';
import { ParkingAvailabilityCategory } from '../types/ParkingAvailabilityCategory';
import { ParkingAvailabilityLocations } from './ParkingAvailabilityLocations';
import { ParkingAvailabilityError } from './ParkingAvailabilityError';
import { ParkingAvailabilityRefresh } from './ParkingAvailabilityRefresh';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { useCommonData } from '../../../app/root/ui/CommonDataContext';
import ContentWrap from '../../../../ui/components/Layout/ContentWrap';
import Responsive from '../../../../ui/utils/Responsive';

interface IParkingAvailabilityProps {
	store: LocationStore;
}

export const ParkingAvailability = observer(
	({ store }: IParkingAvailabilityProps) => {
		const { t } = useTranslation();
		const { parkingInfo } = useCommonData();

		const onReload = useCallback(() => {
			store.load({ parkingInfo });
		}, [store]);

		return (
			<ContentWrap>
				<StyledHeader>
					<StyledH2>{t('live_parking_availability_title')}</StyledH2>
					<ParkingAvailabilityRefresh
						onReload={onReload}
						lastUpdated={store.getLastUpdated()}
					/>
				</StyledHeader>
				{store.error ? (
					<ParkingAvailabilityError onReload={onReload} />
				) : (
					<>
						<ParkingAvailabilityFilters
							onToggle={store.toggleFilter}
							activeCategory={store.filters}
						/>
						<StyledLocationsWrap>
							<ParkingAvailabilityLocations
								isLoading={store.isLoading}
								locations={store.locations.filter(
									(location) =>
										store.filters.has(location.category) ||
										store.filters.has(ParkingAvailabilityCategory.ALL),
								)}
							/>
						</StyledLocationsWrap>
					</>
				)}
			</ContentWrap>
		);
	},
);

const StyledLocationsWrap = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 10px;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		grid-template-columns: 1fr;
	}
`;

const StyledHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
`;

const StyledH2 = styled(H2)`
	margin-bottom: 0;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;
