import React from 'react';
import {
	Card,
	getHeaderStyleCss,
	IResponsiveImageSource,
	Markdown,
} from '../../../../ui';
import styled from '@emotion/styled';
import {
	ICardItemEntry,
	ICardSectionEntry,
	IImageAsset,
} from '../../../shared/api/contentful/types';
import { Section } from './Section';
import { CardsContainer } from '../../../shared/components';
import { ITranslationFunction } from '../../../shared/i18n/translate';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';
import { getListedEntryKey } from '../../../shared/utils/getListedEntryKey';
import { getResponsiveImageUrl } from '../../../shared/image/getResponsiveImageUrl';
import { getUrlForLink } from '../../../app/pages/ui/utils/getUrlForLink';
import { ISiteMap } from '../../../app/pages/domain/models/ISiteMap';
import { withSiteMap } from '../../../app/pages/ui/utils/withSiteMap';

export interface ICardSectionProps {
	section: ICardSectionEntry;
}

export const CardSection = withSiteMap<ICardSectionProps>(
	({ section, siteMap }) => {
		const { t } = useTranslation();
		return (
			<Section>
				{section.fields.title && section.fields.title.trim() !== '' && (
					<h2>{section.fields.title}</h2>
				)}
				<CardsContainer
					fullyClickable={section.fields.clickable}
					columns={[
						// For desktop, if there are less items then columns, then use the item length as columns,
						// with a minimum of 2 columns (i.e. 1 item will be rendering in a 2-column layout).
						Math.max(
							Math.min(
								section.fields.columns,
								section.fields.items?.length ?? 0,
							),
							2,
						),
						{
							tablet: 2,
							mobile: section.fields.responsive ? 1 : 2,
						},
					]}
					cardOrientation={
						section.fields.responsive
							? ['vertical', { mobile: 'horizontal' }]
							: 'vertical'
					}
					childrenHidden={[false, { mobile: section.fields.clickable }]}
					linkHidden={[false, { mobile: section.fields.clickable }]}
					titleFontSize={[
						32,
						{
							tablet: 24,
							mobile: section.fields.clickable ? 20 : 18,
						},
					]}
				>
					{section.fields.items?.map((item, index) =>
						renderCardItem(section, item, index, siteMap, t),
					)}
				</CardsContainer>
			</Section>
		);
	},
);

function renderCardItem(
	section: ICardSectionEntry,
	item: ICardItemEntry,
	index: number,
	siteMap: ISiteMap,
	t: ITranslationFunction,
) {
	if (!item.fields.link) {
		return undefined;
	}

	const linkHref = getUrlForLink(item.fields.link, siteMap);

	if (!linkHref) {
		return undefined;
	}

	return (
		<StyledCard
			key={getListedEntryKey(item, index)}
			title={item.fields.title}
			imageAlt=""
			imageSource={getCardImageSource(item.fields.image, section)}
			linkTitle={item.fields.link && item.fields.link.fields.title}
			linkHref={linkHref}
			screenReaderLinkTitle={t('read_more_about_name', {
				name: item.fields.title,
			})}
			titleHeaderLevel="h2"
			titleAsLinkCard
			fullyClickable={section.fields.clickable}
		>
			{item.fields.body && <Markdown source={item.fields.body} />}
		</StyledCard>
	);
}

function getCardImageSource(
	image: IImageAsset | undefined,
	section: ICardSectionEntry,
): IResponsiveImageSource {
	if (!image) {
		return '';
	}

	const { url } = image.fields.file;

	return [
		getResponsiveImageUrl(url, { w: 720, h: 400, fit: 'thumb' }),
		{
			mobile: section.fields.responsive
				? getResponsiveImageUrl(url, { w: 240, h: 240, fit: 'thumb' })
				: getResponsiveImageUrl(url, { w: 360, h: 200, fit: 'thumb' }),
		},
	];
}

const StyledCard = styled(Card)`
	h2 {
		${getHeaderStyleCss('h3')}
	}
`;
