import React from 'react';
import { IResponsiveImageSource } from '../../../../ui';
import { ContactItem, ContactItemContainer } from '../../../shared/components';
import {
	IContactItemEntry,
	IContactSectionEntry,
	IImageAsset,
} from '../../../shared/api/contentful/types';
import { Section } from './Section';
import {
	ITranslationFunction,
	withTranslate,
} from '../../../shared/i18n/translate';
import { getListedEntryKey } from '../../../shared/utils/getListedEntryKey';
import {
	getResponsiveImageUrl,
	IImageOptions,
} from '../../../shared/image/getResponsiveImageUrl';

const DEFAULT_IMAGE_BACKGROUND_COLOR = 'f7f7f7';

export interface IContactSectionProps {
	section: IContactSectionEntry;
}

export const ContactSection = withTranslate<IContactSectionProps>(
	({ section, t }) => {
		const defaultLinkTitle = t('contact_item_visit_website');

		return (
			<Section>
				{section.fields.title && <h2>{section.fields.title}</h2>}
				<ContactItemContainer>
					{section.fields.items?.map((item, index) =>
						renderContactItem(item, index, defaultLinkTitle, t),
					)}
				</ContactItemContainer>
			</Section>
		);
	},
);

function renderContactItem(
	item: IContactItemEntry,
	index: number,
	defaultLinkTitle: string,
	t: ITranslationFunction,
) {
	return (
		<ContactItem
			key={getListedEntryKey(item, index)}
			title={item.fields.title}
			imageSource={getContactImageSource(
				item.fields.image,
				item.fields.imageBackgroundColor,
			)}
			imageAlt={item.fields.title}
			phoneNumber={item.fields.phoneNumber}
			linkTitle={item.fields.websiteTitle || defaultLinkTitle}
			linkHref={item.fields.website}
			phoneImageLabel={t('contact_phone_image_label')}
		/>
	);
}

export function getContactImageSource(
	image: IImageAsset | undefined,
	backgroundColor?: string,
): IResponsiveImageSource {
	if (!image) {
		return '';
	}

	const { url } = image.fields.file;

	const options: IImageOptions = {
		fit: 'pad',
		bg: `rgb:${backgroundColor || DEFAULT_IMAGE_BACKGROUND_COLOR}`,
	};

	return [
		getResponsiveImageUrl(url, { ...options, w: 720, h: 400 }),
		{
			mobile: getResponsiveImageUrl(url, { ...options, w: 160, h: 200 }),
			mobileXL: getResponsiveImageUrl(url, { ...options, w: 480, h: 480 }),
		},
	];
}
