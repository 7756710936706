'use client';

import React from 'react';
import {
	FixedGrid,
	FixedGridItem,
	IBreakpointName,
	ITheme,
} from '../../../../ui';
import styled from '@emotion/styled';
import { INewsCardEntry } from '../../../shared/api/contentful/types/INewsCard';
import { INewsSectionEntry } from '../../../shared/api/contentful/types/INewsSection';
import NewsCardNoImage from '../../../../../assets/icons/no-image.svg?reference';
import { getListedEntryKey } from '../../../shared/utils/getListedEntryKey';
import NewsCard from './NewsCard';
import NewsSectionWrapper from './NewsSectionWrapper';
import { getMaxWidthBasedOnColumns } from '../../helpers/getMaxWidthBasedOnColumns';
import Carousel from '../../../../ui/components/image-carousel/Carousel';
import { CarouselItem } from '../../../../ui/components/image-carousel/CarouselItem';
import {
	getOverlapWithHeaderStyles,
	StyledHeader,
} from '../../../shared/components';
import Responsive from '../../../../ui/utils/Responsive';

export interface INewsSectionProps {
	section: INewsSectionEntry;
	maxItems?: number;
}

const renderNewsCard = (item: INewsCardEntry, index: number) => {
	const {
		fields: { title, image, publicationDate, summary, url },
	} = item;
	const key = getListedEntryKey(item, index);

	const imageSrc = image ? image.fields.file.url : NewsCardNoImage;

	return (
		<NewsCard
			key={key}
			title={title}
			summary={summary}
			imageSource={imageSrc}
			linkHref={url}
			publicationDate={publicationDate}
			variant="vertical"
		/>
	);
};

export const NewsSection = ({ section, maxItems }: INewsSectionProps) => {
	const { title, sectionLabel: label } = section.fields;
	const isMobileXL = Responsive.useMatchesBreakpoint('mobileXL');

	const items = maxItems
		? section.fields.items.slice(0, maxItems)
		: section.fields.items;

	return (
		<NewsSectionWrapper title={title} label={label} removeViewAllBtn>
			{!isMobileXL ? (
				<StyledFixedGrid columns={4}>
					{section.fields.items &&
						items.map((item, index) => (
							<StyledFixedGridItem key={item.id} columns={4} minWidthMobile>
								{renderNewsCard(item, index)}
							</StyledFixedGridItem>
						))}
				</StyledFixedGrid>
			) : (
				<CarouselWrapper>
					<Carousel>
						{section.fields.items.map((item, index) => (
							<CarouselItem
								ariaSlideIndex={index}
								ariaSlideTitle={item.fields.title || ''}
								ariaTotalNumberOfSlides={section.fields.items.length}
								key={item.id}
							>
								{renderNewsCard(item, index)}
							</CarouselItem>
						))}
					</Carousel>
				</CarouselWrapper>
			)}
		</NewsSectionWrapper>
	);
};

const CarouselWrapper = styled.div<{}, ITheme>`
	${StyledHeader} + style + &,
      ${StyledHeader} + & {
		${getOverlapWithHeaderStyles()}
	}

	margin-left: -20px;
	align-self: stretch;
`;

const StyledFixedGrid = styled(FixedGrid)`
	margin-top: 0;
	margin-left: 0;
	gap: 24px;

	@media (${Responsive.getMediaQueryForBreakpoint('tablet')}) {
		overflow: auto;
		flex-wrap: nowrap;
		gap: 16px;
	}
`;

const StyledFixedGridItem = styled(FixedGridItem)<{
	columns: number | [number, Partial<{ [key in IBreakpointName]: number }>];
	minWidthMobile?: boolean;
}>`
	display: flex;
	margin-top: 0;
	margin-left: 0;
	flex: 1;

	${({ columns, minWidthMobile }) =>
		getMaxWidthBasedOnColumns(columns, minWidthMobile)};
`;
