import React from 'react';
import { ParkingAvailability } from '../../../parking-availability/ui/components/ParkingAvailability';
import { useLocationStore } from '../../../parking-availability/ui/hooks/useLocationStore';
import { useCommonData } from '../../../app/root/ui/CommonDataContext';

export const ParkingAvailabilitySection = () => {
	const locationStore = useLocationStore();
	const { parkingInfo } = useCommonData();

	locationStore.load({ parkingInfo });

	return <ParkingAvailability store={locationStore} />;
};
