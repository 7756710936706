import React, { FC } from 'react';
import { Highlight, IResponsiveImageSource, Markdown } from '../../../../ui';
import {
	IHighlightSectionEntry,
	IImageAsset,
} from '../../../shared/api/contentful/types';
import { Section } from './Section';

import { getResponsiveImageUrl } from '../../../shared/image/getResponsiveImageUrl';
import { useUrlForLink } from '../../../app/pages/ui/utils/useUrlForLink';

interface IHighlightSectionProps {
	section: IHighlightSectionEntry;
}

export const HighlightSection: FC<IHighlightSectionProps> = ({ section }) => {
	const url = useUrlForLink(section.fields.link);

	return (
		<Section>
			<Highlight
				elevated={section.fields.clickable}
				fullyClickable={section.fields.clickable}
				title={section.fields.title}
				imageAlt={section.fields.title}
				imageSource={getHighlightImageSource(section.fields.image)}
				imagePosition={
					section.fields.imagePosition === 'right'
						? ['last', { mobileXL: 'first' }]
						: 'first'
				}
				orientation={['horizontal', { mobileXL: 'vertical' }]}
				linkTitle={section.fields.link && section.fields.link.fields.title}
				linkHref={url}
				withBackgroundColor={section.fields.background}
				showLinkAsButton={section.fields.showLinkAsButton}
			>
				<Markdown source={section.fields.body} />
			</Highlight>
		</Section>
	);
};

function getHighlightImageSource(
	image: IImageAsset | undefined,
): IResponsiveImageSource {
	if (!image) {
		return '';
	}

	const { url } = image.fields.file;

	return [
		getResponsiveImageUrl(url, { w: 1200, h: 900, fit: 'thumb' }),
		{
			mobileXL: getResponsiveImageUrl(url, { w: 720, h: 480, fit: 'thumb' }),
		},
	];
}
