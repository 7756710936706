import { Component } from 'react';
import { logError } from '../../../../shared/error/logError';
import { IErrorSectionProps } from './ErrorSection';

interface ISectionErrorBoundaryProps extends IErrorSectionProps {
	children?: React.ReactNode;
}

interface IState {
	hasError: boolean;
}

export class SectionErrorBoundary extends Component<
	ISectionErrorBoundaryProps,
	IState
> {
	public state = { hasError: false };

	public static getDerivedStateFromError() {
		return { hasError: true };
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public componentDidCatch(error: Error, info: any) {
		logError(error, info);
	}

	public render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (!hasError) {
			return children || null;
		}

		return null;
	}
}
