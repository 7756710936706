import React from 'react';
import { Iframe } from '../../../../ui';
import { IIframeSectionEntry } from '../../../shared/api/contentful/types';
import { Section } from './Section';
import { useTranslation } from '../../../shared/i18n/translate/useTranslation';

interface IIframeSectionProps {
	section: IIframeSectionEntry;
}

export const IframeSection = ({ section }: IIframeSectionProps) => {
	const { t } = useTranslation();

	return (
		<Section>
			<Iframe
				title={section.fields.title || t('iframe_title_alt')}
				url={section.fields.url}
				maxWidth={section.fields.width}
				maxHeight={section.fields.height}
			/>
		</Section>
	);
};
